import { defaultDataIdFromObject, InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
import { ApolloLink, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import storage from '@/lib/session-storage';
import { onError } from 'apollo-link-error';

import { ApolloClient, ApolloClientOptions, Resolvers } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import createHistory from 'history/createBrowserHistory';
import defaults from './defaults';
import resolvers from './resolvers';
import typeDefs from './type-defs';

import auth from '@/firebase/firebase.config';
import { doSignOut } from '@/firebase/auth';

export const history = createHistory();

function getLinks() {
	const errorLink = onError(({ networkError }) => {
		if (networkError && (networkError as any).statusCode === 401) {
			console.warn('Create Links Unauthorized, signing out'); // eslint-disable-line no-console
			doSignOut();
		} else {
			// eslint-disable-next-line no-console
			console.error(networkError);
		}
	});

	const httpRequestLink = createHttpLink({
		uri: `${window.env.JSON_API_URL}/graphql`,
		credentials: 'include',
		fetch: async (uri: any, options: any) => {
			const res = await fetch(uri, options);
			const token = res.headers.get('x-xsrf-token');
			if (token) {
				storage.nonce.set(token);
			}

			return Promise.resolve(res) as any;
		}
	});

	const authLink = setContext(async (_, { headers }) => {
		const xsrf = storage.nonce.get();

		if (auth.currentUser) {
			const idToken = await findFirebaseToken();
			console.log('firebase current user is ' + idToken);
			return {
				headers: {
					...headers,
					'X-XSRF-Token': xsrf,
					Authorization: `Bearer ${idToken}`
				}
			};
		}
	});

	const findFirebaseToken = async () => {
		return (await auth.currentUser?.getIdToken()) ?? null;
	};

	const httpLink = ApolloLink.from([authLink, httpRequestLink]);

	const wsLink = new WebSocketLink({
		uri: `${window.env.WS_URL}/graphql`,
		options: {
			reconnect: true,
			connectionParams: async () => {
				const idToken = await findFirebaseToken();
				return {
					Authorization: `Bearer ${idToken}`
				};
			},
			connectionCallback: err => {
				if (err) {
					console.error(err); // eslint-disable-line no-console
				} else {
					console.log('WebSocket connected'); // eslint-disable-line no-console
				}
			},
			// Connect only when first subscription is created to prevent unauthenticated connection
			lazy: true
		}
	});

	const networkLink = split(
		// Split based on operation type
		({ query }) => {
			const definition = getMainDefinition(query);
			return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
		},
		wsLink,
		httpLink
	);

	return ApolloLink.from([errorLink, networkLink]);
}

function getApolloClientConfig(): ApolloClientOptions<NormalizedCacheObject> {
	const typenameBlacklist = new Set(['OrderPayment', 'ProductWithDelivery']);
	const cache = new InMemoryCache({
		dataIdFromObject: object =>
			object.__typename && typenameBlacklist.has(object.__typename) ? null : defaultDataIdFromObject(object)
	});
	cache.writeData({ data: defaults(history) });

	return {
		link: getLinks(),
		cache,
		resolvers: resolvers(history, cache) as Resolvers,
		typeDefs,
		connectToDevTools: false
	};
}

let apolloClient: ApolloClient<NormalizedCacheObject>;

export const getClient = () => {
	if (!apolloClient) {
		try {
			apolloClient = new ApolloClient(getApolloClientConfig());
		} catch (err) {
			console.error('Error getting appollo client ' + err); // eslint-disable-line no-console
		}
	}

	return apolloClient;
};
