import auth from './firebase.config';
import {
	createUserWithEmailAndPassword,
	signInWithPopup,
	signInWithEmailAndPassword,
	sendPasswordResetEmail,
	confirmPasswordReset,
	updatePassword,
	GoogleAuthProvider,
	OAuthProvider
} from 'firebase/auth';

export const doCreateUserWithEmailAndPassword = async (email, password) => {
	const res = await createUserWithEmailAndPassword(auth, email, password);
	return res;
};

export const doPasswordChange = password => {
	return updatePassword(auth.currentUser, password);
};

export const sendPasswordReset = async email => {
	const res = await sendPasswordResetEmail(auth, email);
	return res;
};

export const doPasswordReset = async (oobCode, newPassword) => {
	const result = await confirmPasswordReset(auth, oobCode, newPassword);
	return result;
};

export const doSignInWithMicrosoft = async () => {
	const provider = new OAuthProvider('microsoft.com');
	const result = await signInWithPopup(auth, provider);
	return result;
};

export const doSignInWithGoogle = async () => {
	const provider = new GoogleAuthProvider();
	const result = await signInWithPopup(auth, provider);
	return result;
};

export const doSignInWithEmailPassword = async (email, password) => {
	const res = await signInWithEmailAndPassword(auth, email, password);
	return res;
};

export const doSignOut = async () => {
	const s = await auth.signOut();
	return s;
};

export const isUserLoggedIn = () => {
	return Boolean(auth.currentUser);
};
